const Menu = [
  {
    heading: "menu",
    route: "/enrollment",
    pages: [
      {
        heading: "Enrollment Summary",
        route: "/enrollment/enrollment-summary",
        fontIcon: "fa-user",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Attendance Performance",
        route: "/enrollment/attendance-performance",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Trainee License Information",
        route: "/enrollment/training-license",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
    ],
  },
];

export default Menu;
